import { Divider, Paper, Typography } from "@mui/material";
import React from "react";

export function CustomCard(p: { title: string; children?: React.ReactNode }) {
	return (
		<Paper>
			<Typography variant="h6" style={{ padding: "10px 15px " }}>
				{p.title}
			</Typography>
			<Divider />
			<div
				style={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				{p.children}
			</div>
		</Paper>
	);
}
