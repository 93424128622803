import { serverRequest } from "./APIHelper";

export interface AdminLogMessage {
	id: number;
	admin_id: number;
	ip: string;
	time: number;
	action: string;
	args: any;
	format: string;
}

export class AdminLogsHelper {
	/**
	 * Get the list of admin log actions from the server
	 */
	static async GetLogs(): Promise<AdminLogMessage[]> {
		return (await serverRequest("logs/list")).map((el: any) => {
			if (typeof el.action === "string") return el;

			for (let key in el.action) {
				if (!el.action.hasOwnProperty(key)) continue;

				el.args = el.action[key];
				el.action = key;
			}
			return el;
		});
	}
}
