/**
 * Account utilities
 *
 * @author Pierre Hubert
 */

import { AccountHelper } from "../helpers/AccountHelper";

export function adminID(): number {
	return AccountHelper.currentAccount.id;
}

export function canManageAdmins(): boolean {
	return AccountHelper.currentAccount.roles.includes("manage_admins");
}

export function canManageComunicUsers(): boolean {
	return AccountHelper.currentAccount.roles.includes("manage_users");
}
