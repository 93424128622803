import { CircularProgress } from "@mui/material";

export function CenterCircularProgress() {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			<CircularProgress></CircularProgress>
		</div>
	);
}
