/**
 * Asynchronous loading widget
 *
 * @author Pierre Hubert
 */

import { Button, Paper } from "@mui/material";
import React, { ReactNode } from "react";
import { CenterCircularProgress } from "./CenterCircularProgress";

enum Status {
	LOADING,
	ERROR,
	SUCCESS,
}

export interface AsyncWidgetProperties {
	key?: number | string;
	load: () => Promise<void>;
	errorMessage: string;
	onBuild: () => ReactNode;
}

interface AsyncWidgetState {
	status: Status;
	key?: number | string;
}

export class AsyncWidget extends React.Component<
	AsyncWidgetProperties,
	AsyncWidgetState
> {
	constructor(props: any) {
		super(props);

		this.state = {
			status: Status.LOADING,
			key: this.props.key,
		};

		this.reload = this.reload.bind(this);
	}

	async reload() {
		try {
			this.setState({ status: Status.LOADING });

			await this.props.load();

			this.setState({ status: Status.SUCCESS });
		} catch (e) {
			console.error(e);
			this.setState({ status: Status.ERROR });
		}
	}

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate() {
		if (this.state.key !== this.props.key) {
			this.setState({ key: this.props.key });
			this.reload();
		}
	}

	render() {
		if (this.state.status === Status.ERROR)
			return (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100%",
					}}
				>
					<Paper>
						<div style={{ padding: "5px 10px" }}>
							{this.props.errorMessage}
							&nbsp;
							<Button onClick={this.reload}>Try again</Button>
						</div>
					</Paper>
				</div>
			);

		if (this.state.status === Status.SUCCESS) return this.props.onBuild();

		return <CenterCircularProgress></CenterCircularProgress>;
	}
}
