/**
 * API request helper
 *
 * @author Pierre Hubert
 */

import { AccountHelper } from "./AccountHelper";
import { ConfigHelper } from "./ConfigHelper";

/**
 * Perform an API request
 *
 * @param uri Target URI on the server
 * @param args Arguments to include in the request
 * @returns The result of the request, in case of success,
 * @throws An exception in case of failure
 */
export async function serverRequest(
	uri: string,
	args?: any,
	continueOnError = false
): Promise<any> {
	const requestArguments = args || {};

	const fd = new FormData();
	for (let arg in requestArguments) {
		if (requestArguments.hasOwnProperty(arg))
			fd.append(arg, requestArguments[arg]);
	}

	// Add access token, if any
	if (AccountHelper.hasAccessToken)
		fd.append("token", AccountHelper.accessToken);

	const result = await fetch((await ConfigHelper.apiURL()) + uri, {
		method: "POST",
		body: fd,
	});

	if (result.status !== 200) {
		if (continueOnError) return { error: result.status };
		throw new Error("Request failed with status " + result.status);
	}

	return await result.json();
}
