/**
 * Initialization widget
 *
 * @author Pierre Hubert
 */

import React from "react";
import { AccountHelper } from "../../helpers/AccountHelper";
import { AdminRolesHelper } from "../../helpers/AdminRolesHelper";
import { LoginRoute } from "../routes/LoginRoute";
import { MainRoute } from "../routes/MainRoute";
import { AsyncWidget } from "./AsyncWidget";

interface InitWidgetState {
	signedIn: boolean;
}

export class InitWidget extends React.Component<{}, InitWidgetState> {
	constructor(props: any) {
		super(props);

		this.state = {
			signedIn: false,
		};

		this.init = this.init.bind(this);
		this.build = this.build.bind(this);
	}

	async init() {
		this.setState({ signedIn: false });

		if (AccountHelper.hasAccessToken) {
			await AccountHelper.refreshCurrentAccountInfo();

			await AdminRolesHelper.LoadRolesList();

			this.setState({ signedIn: true });
		}
	}

	render() {
		return (
			<AsyncWidget
				errorMessage="Failed to initialize application!"
				load={this.init}
				onBuild={this.build}
			></AsyncWidget>
		);
	}

	build() {
		return this.state.signedIn ? <MainRoute /> : <LoginRoute></LoginRoute>;
	}
}
