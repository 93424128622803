import { Grid } from "@mui/material";
import React from "react";
import { CustomCard } from "../widgets/CustomCard";

export function SettingsSection(p: {
	title: string;
	children?: React.ReactNode;
}) {
	return (
		<Grid item sm={6}>
			<CustomCard title={p.title}>{p.children}</CustomCard>
		</Grid>
	);
}
