import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import React from "react";
import { AccountHelper, AdminAccount } from "../../helpers/AccountHelper";
import {
	AdminLogMessage,
	AdminLogsHelper,
} from "../../helpers/AdminLogsHelper";
import { AsyncWidget } from "../widgets/AsyncWidget";
import { PageTitle } from "../widgets/PageTitle";
import { TimestampWidget } from "../widgets/TimestampWidget";

/**
 * View admin logs
 *
 * @author Pierre Hubert
 */
export class AccountLogsRoute extends React.Component<
	{},
	{ logs: AdminLogMessage[]; admins: AdminAccount[] }
> {
	constructor(p: any) {
		super(p);

		this.load = this.load.bind(this);
		this.build = this.build.bind(this);
	}

	async load() {
		const admins = await AccountHelper.GetAdminsList();
		const logs = await AdminLogsHelper.GetLogs();

		this.setState({
			admins: admins,
			logs: logs,
		});
	}

	getAdminName(id: number): string {
		const admin = this.state.admins.find((a) => a.id === id);
		return admin ? admin.name : "Unknown admin";
	}

	build() {
		return (
			<div>
				<PageTitle name="Admin activity records" />

				<TableContainer component={Paper}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Admin</TableCell>
								<TableCell align="right">Time</TableCell>
								<TableCell align="right">IP address</TableCell>
								<TableCell align="right">Action</TableCell>
								<TableCell align="right">Details</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.logs.reverse().map((message) => {
								let formattedMessage = message.format;
								for (let arg in message.args) {
									if (message.args.hasOwnProperty(arg))
										formattedMessage =
											formattedMessage.replace(
												"{" + arg + "}",
												message.args[arg]
											);
								}

								// Handle [admin] tag
								const regex: any =
									"\\[admin\\][0-9]+\\[/admin\\]";
								const adminsReferences = Array.from(
									formattedMessage.matchAll(regex)
								);

								for (let entry of adminsReferences) {
									const pattern = entry[0];
									const adminId = Number(
										entry[0].split("]")[1].split("[")[0]
									);
									const adminName =
										this.getAdminName(adminId);
									formattedMessage = formattedMessage.replace(
										pattern,
										adminName
									);
								}

								return (
									<TableRow key={message.id} hover>
										<TableCell>
											{this.getAdminName(
												message.admin_id
											)}
										</TableCell>
										<TableCell align="right">
											<TimestampWidget
												time={message.time}
											/>
										</TableCell>
										<TableCell align="right">
											{message.ip}
										</TableCell>
										<TableCell align="right">
											{message.action}
										</TableCell>
										<TableCell align="right">
											{formattedMessage}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<p style={{ color: "white" }}>
					Note: your old activity records are automatically deleted
					after a period of time.
				</p>
			</div>
		);
	}

	render() {
		return (
			<AsyncWidget
				errorMessage="Failed to retrieve admin logs!"
				onBuild={this.build}
				load={this.load}
			/>
		);
	}
}
