/**
 * Configuration helper
 *
 * @author Pierre Hubert
 */

let cache: any;

export class ConfigHelper {
	static async load() {
		if (!cache)
			cache = JSON.parse(await (await fetch("/config.json")).text());
	}

	/**
	 * Get server API url
	 */
	static async apiURL(): Promise<string> {
		await this.load();
		return cache.api_url;
	}
}
