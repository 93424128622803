import { createTheme, ThemeProvider } from "@mui/material";
import { lightBlue, pink } from "@mui/material/colors";
import React from "react";
import "./App.css";
import { ApplicationDialogsProvider } from "./ui/widgets/DialogsProvider";
import { InitWidget } from "./ui/widgets/InitWidget";

function App() {
	const darkTheme = createTheme({
		palette: {
			mode: "dark",
			primary: lightBlue,
			secondary: pink,
		},
	});

	return (
		<ThemeProvider theme={darkTheme}>
			<div className="App">
				{/* Holder of dialogs */}
				<ApplicationDialogsProvider></ApplicationDialogsProvider>

				{/* Initialization widget */}
				<InitWidget></InitWidget>
			</div>
		</ThemeProvider>
	);
}

export default App;
