import {
	Button,
	Divider,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import React from "react";
import { AccountHelper, AdminAccount } from "../../helpers/AccountHelper";
import { AdminAccountKey, AdminKeyHelper } from "../../helpers/AdminKeyHelper";
import { CopyToClipboard } from "../../utils/ClipboardUtils";
import { AsyncWidget } from "../widgets/AsyncWidget";
import {
	input,
	matAlert,
	matConfirm,
	snackbar,
} from "../widgets/DialogsProvider";
import { TimestampWidget } from "../widgets/TimestampWidget";
import { SettingsSection } from "./SettingsSection";

export class KeySettingsSection extends React.Component<
	{ admin: AdminAccount },
	{ keys: AdminAccountKey[]; counter: number }
> {
	constructor(props: any) {
		super(props);

		this.state = {
			keys: [],
			counter: 1,
		};

		this.load = this.load.bind(this);
		this.build = this.build.bind(this);
		this.generateResetToken = this.generateResetToken.bind(this);
		this.registerNewKey = this.registerNewKey.bind(this);
		this.deleteKey = this.deleteKey.bind(this);
	}

	async load() {
		const keys = await AdminKeyHelper.GetAdminKeys(this.props.admin.id);

		this.setState({ keys: keys });
	}

	async generateResetToken() {
		try {
			if (
				!(await matConfirm(
					"Do you really want to generate a reset token for this account?"
				))
			)
				return;

			const token = await AccountHelper.GenerateResetToken(
				this.props.admin.id
			);

			CopyToClipboard(token.token);
			snackbar("Reset token was successfully copied to the clipboard!");
		} catch (e) {
			console.error(e);
			matAlert("Failed to generate a token!");
		}
	}

	async registerNewKey() {
		try {
			const challenge =
				await AdminKeyHelper.GetKeyRegistrationChallenge();
			const credential = await navigator.credentials.create(challenge);

			if (credential == null) throw new Error("Operation aborted!");

			const name = await input({
				label: "Key name",
				maxLength: 40,
				minLength: 2,
			});

			const password = await input({
				label: "Key password (10 characters min)",
				maxLength: 4000,
				minLength: 10,
				type: "password",
			});

			await AdminKeyHelper.RegisterKey(name, credential, password);

			snackbar("Successfully enrolled a new key!");

			this.setState({ counter: this.state.counter + 1 });
		} catch (e) {
			console.error(e);
			matAlert("Failed to register a new key!");
		}
	}

	async deleteKey(key: AdminAccountKey) {
		try {
			if (
				!(await matConfirm(
					"Do you really want to delete the key '" + key.name + "' ?"
				))
			)
				return;

			await AdminKeyHelper.DeleteAuthKey(this.props.admin.id, key.id);

			snackbar("The key was successfully deleted!");
			this.setState({ counter: this.state.counter + 1 });
		} catch (e) {
			console.error(e);
			matAlert("Failed to delete key!");
		}
	}

	render() {
		return (
			<AsyncWidget
				errorMessage="Failed to load admin keys!"
				load={this.load}
				onBuild={this.build}
				key={this.props.admin.id + "-" + this.state.counter}
			></AsyncWidget>
		);
	}

	build() {
		return (
			<SettingsSection title="Security keys">
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Key name</TableCell>
							<TableCell align="right">Date added</TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.keys.map((key) => (
							<TableRow key={key.id}>
								<TableCell component="th" scope="row">
									{key.name}{" "}
									{key.has_password ? (
										<Tooltip
											disableFocusListener
											title="This key requires a password to be used"
											placement="top"
											arrow
										>
											<LockIcon fontSize="inherit" />
										</Tooltip>
									) : (
										<></>
									)}
								</TableCell>
								<TableCell align="right">
									<TimestampWidget time={key.time_add} />
								</TableCell>
								<TableCell align="right">
									<IconButton
										aria-label="delete"
										size="small"
										onClick={() => this.deleteKey(key)}
									>
										<Delete />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>

				<Divider />

				{/* Action buttons */}
				<div
					style={{
						textAlign: "right",
						margin: "5px 10px",
					}}
				>
					<Button onClick={this.generateResetToken}>
						New reset token
					</Button>
					<Button
						disabled={
							this.props.admin.id !==
							AccountHelper.currentAccount.id
						}
						onClick={this.registerNewKey}
					>
						Register a new key
					</Button>
				</div>
			</SettingsSection>
		);
	}
}
