/**
 * Main route
 *
 * @author Pierre Hubert
 */

import { Home, Person } from "@mui/icons-material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import GroupIcon from "@mui/icons-material/Group";
import HistoryIcon from "@mui/icons-material/History";
import {
	AppBar,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Toolbar,
	Typography,
} from "@mui/material";
import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useMatch,
	useNavigate,
	useParams,
} from "react-router-dom";
import { AccountHelper, AdminAccountRole } from "../../helpers/AccountHelper";
import { AccountLogsRoute } from "./AccountLogsRoute";
import { AccountSettingsRoute } from "./AccountSettingsRoute";
import { AccountsListRoute } from "./AccountsListRoute";
import { ComunicUserRoute } from "./ComunicUserRoute";
import { HomeRoute } from "./HomeRoute";
import { NotFoundRoute } from "./NotFoundRoute";
import { SearchComunicUsersRoute } from "./SearchComunicUsersRoute";

function Menu() {
	return (
		<div>
			<List component="nav" aria-label="main mailbox folders">
				<MainMenuItem title="Home" icon={<Home />} uri="/" />
				<MainMenuItem
					title="Comunic Users"
					icon={<GroupIcon />}
					uri="/users"
					needsRole="manage_users"
				/>
				<MainMenuItem
					title="Administrators"
					icon={<GroupIcon />}
					uri="/accounts"
				/>
				<MainMenuItem
					title="Admin Logs"
					icon={<HistoryIcon />}
					uri="/logs"
				/>
				<Divider />
				<MainMenuItem
					title="My account"
					icon={<Person />}
					uri={"/accounts/" + AccountHelper.currentAccount.id}
				/>
			</List>
		</div>
	);
}

function MainMenuItem(p: {
	title: string;
	icon: React.ReactNode;
	uri: string;
	needsRole?: AdminAccountRole;
}) {
	const n = useNavigate();
	const openMyAccount = () => n(p.uri);

	const selected = useMatch(p.uri) != null;

	// Check if roles are filtered
	if (p.needsRole) {
		if (!AccountHelper.currentAccount.roles.includes(p.needsRole))
			return <span></span>;
	}

	return (
		<ListItem button onClick={openMyAccount} selected={selected}>
			<ListItemIcon>{p.icon}</ListItemIcon>
			<ListItemText primary={p.title} />
		</ListItem>
	);
}

export function MainRoute() {
	const signOut = () => {
		AccountHelper.signOut();
	};

	return (
		<Router>
			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Toolbar></Toolbar>

				<AppBar
					position="fixed"
					style={{
						zIndex: 1,
						width: `100%`,
						color: "white",
					}}
					color="primary"
				>
					<Toolbar>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							style={{ flexGrow: 1 }}
						>
							Comunic Console
						</Typography>

						<Typography>
							{AccountHelper.currentAccount.name}
							<br />
							<small>{AccountHelper.currentAccount.email}</small>
						</Typography>

						<IconButton aria-label="Sign out" onClick={signOut}>
							<CloseSharpIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<div
					style={{
						display: "flex",
						flex: "1",
						flexDirection: "row",
						width: "100%",
					}}
				>
					<Paper style={{ width: "200px" }}>
						<Menu></Menu>
					</Paper>

					<div style={{ flex: "1", padding: "50px" }}>
						<div
							style={{
								height: "100%",
								margin: "auto",
								maxWidth: "1280px",
							}}
						>
							<Routes>
								<Route path="/" element={<HomeRoute />} />

								<Route
									path="/users"
									element={<SearchComunicUsersRoute />}
								/>

								<Route
									path="/user/:id"
									element={<ComunicUserRouteProxy />}
								/>

								<Route
									path="/accounts"
									element={<AccountsListRoute />}
								/>

								<Route
									path="/accounts/:id"
									element={<AccountSettingsRoute />}
								/>

								<Route
									path="/logs"
									element={<AccountLogsRoute />}
								/>

								<Route path="*" element={<NotFoundRoute />} />
							</Routes>
						</div>
					</div>
				</div>
			</div>
		</Router>
	);
}

function ComunicUserRouteProxy() {
	let { id } = useParams<{ id: string }>();

	return <ComunicUserRoute userID={Number(id)} />;
}
