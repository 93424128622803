import { Typography } from "@mui/material";

/**
 * Page title widget
 *
 * @author Pierre Hubert
 */
export function PageTitle(p: { name: string; actions?: React.ReactNode }) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				marginBottom: "50px",
			}}
		>
			<Typography variant="h4" style={{ color: "white" }}>
				{p.name}
			</Typography>

			<div>{p.actions}</div>
		</div>
	);
}
