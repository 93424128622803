/**
 * Admin roles helper
 *
 * @author Pierre Hubert
 */

import { serverRequest } from "./APIHelper";

export interface AdminRole {
	id: string;
	name: string;
	description: string;
}

export let RolesList: AdminRole[] = [];

export class AdminRolesHelper {
	/**
	 * Load the list of roles.
	 *
	 * @throws In case of failure
	 */
	static async LoadRolesList() {
		RolesList = await serverRequest("roles/list");
	}

	/**
	 * Toggle a role for an administrator
	 *
	 * @param adminID Target administrator ID
	 * @param role The role to toggle
	 */
	static async ToggleAdminRole(
		adminID: number,
		role: AdminRole,
		enable: boolean
	) {
		await serverRequest("roles/toggle", {
			adminID: adminID,
			role: role.id,
			enable: enable,
		});
	}
}
