/**
 * Comunic users management
 *
 * @author Pierre Hubert
 */

import { serverRequest } from "./APIHelper";

export interface SearchResult {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	account_image?: string;
}

export interface ComunicUser {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	account_creation_time: number;
	last_activity: number;
	page_visibility: "private" | "public" | "open";
	directory?: string;
	account_image?: string;
	account_image_visibility: "friends" | "public" | "open";
	friend_list_public: boolean;
	is_email_public: boolean;
	personal_website?: string;
	public_note?: string;
	location?: string;
	block_comments: boolean;
	allow_posts_from_friends: boolean;
	allow_mails: boolean;
	lang: string;
}

export class ComunicUsersHelper {
	/**
	 * Search for Comunic users
	 *
	 * @param name The name of the user to search
	 * @param email The email address of the user to search
	 */
	static async SearchUser(
		name: string,
		email: string
	): Promise<SearchResult[]> {
		return await serverRequest("users/search", {
			name: name,
			email: email,
		});
	}

	/**
	 * Get information about a single user
	 */
	static async GetSingle(id: number): Promise<ComunicUser> {
		return await serverRequest("users/info", {
			user_id: id,
		});
	}

	/**
	 * Change the email address of a user
	 */
	static async ChangeEmail(id: number, newEmail: string): Promise<void> {
		await serverRequest("users/change_email_address", {
			user_id: id,
			new_mail: newEmail,
		});
	}

	/**
	 * Create a password reset link for a user
	 */
	static async CreatePasswordRecoveryLink(id: number): Promise<string> {
		return (
			await serverRequest("users/create_password_reset_link", {
				user_id: id,
			})
		).url;
	}

	/**
	 * Delete a user account
	 */
	static async DeleteUserAccount(id: number): Promise<string> {
		return (
			await serverRequest("users/delete_account", {
				user_id: id,
			})
		).url;
	}
}
