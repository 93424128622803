/**
 * Account settings route
 *
 * @author Pierre Hubert
 */

import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { AccountHelper, AdminAccount } from "../../helpers/AccountHelper";
import { adminID, canManageAdmins } from "../../utils/AccountUtils";
import { GeneralSettings } from "../accountSettings/GeneralSettings";
import { KeySettingsSection } from "../accountSettings/KeySettingsSection";
import { RoleSettingsSection } from "../accountSettings/RoleSettingsSection";
import { AsyncWidget } from "../widgets/AsyncWidget";
import { PageTitle } from "../widgets/PageTitle";

export function AccountSettingsRoute() {
	let params: any = useParams();

	return <AccountSettingsRouteInner id={Number(params.id)} />;
}

interface SettingsRouteProps {
	id: number;
}

interface SettingsRouteState {
	account: AdminAccount;
	version: number;
}

class AccountSettingsRouteInner extends React.Component<
	SettingsRouteProps,
	SettingsRouteState
> {
	constructor(props: any) {
		super(props);

		this.load = this.load.bind(this);
		this.build = this.build.bind(this);

		this.state = {
			account: {} as AdminAccount,
			version: 1,
		};

		this.reload = this.reload.bind(this);
	}

	reload() {
		this.setState({ version: this.state.version + 1 });
	}

	async load() {
		const account = await AccountHelper.getAdminInfo(this.props.id);

		this.setState({ account: account });
	}

	render() {
		return (
			<AsyncWidget
				key={this.props.id + "-" + this.state.version}
				errorMessage="Failed to load admin information!"
				onBuild={this.build}
				load={this.load}
			></AsyncWidget>
		);
	}

	build() {
		return (
			<div>
				<PageTitle name="Account settings"></PageTitle>
				<Grid container spacing={2}>
					<GeneralSettings
						admin={this.state.account}
						editable={
							this.props.id === adminID() || canManageAdmins()
						}
					/>

					{this.props.id === adminID() || canManageAdmins() ? (
						<KeySettingsSection admin={this.state.account} />
					) : (
						<div></div>
					)}

					<RoleSettingsSection
						admin={this.state.account}
						editable={canManageAdmins()}
						onChanged={this.reload}
					/>
				</Grid>
			</div>
		);
	}
}
