import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import {
	Avatar,
	Button,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";
import React from "react";
import {
	ComunicUser,
	ComunicUsersHelper,
} from "../../helpers/ComunicUsersHelper";
import { CopyToClipboard } from "../../utils/ClipboardUtils";
import { validateEmail } from "../../utils/StringsUtils";
import { AsyncWidget } from "../widgets/AsyncWidget";
import { CustomCard } from "../widgets/CustomCard";
import {
	input,
	matAlert,
	matConfirm,
	snackbar,
} from "../widgets/DialogsProvider";
import { PageTitle } from "../widgets/PageTitle";
import { TimestampWidget } from "../widgets/TimestampWidget";

interface UserProperty {
	name: string;
	value?: string | number;
	bool?: boolean;
	child?: React.ReactElement;
}

/**
 * Comunic user management route
 *
 * @author Pierre Hubert
 */
export class ComunicUserRoute extends React.Component<
	{ userID: number },
	{ user: ComunicUser }
> {
	constructor(p: any) {
		super(p);

		this.load = this.load.bind(this);
		this.build = this.build.bind(this);

		this.changeEmailAddress = this.changeEmailAddress.bind(this);
		this.createPasswordResetLink = this.createPasswordResetLink.bind(this);
		this.deleteAccount = this.deleteAccount.bind(this);
	}

	get user(): ComunicUser {
		return this.state.user;
	}

	async load() {
		const user = await ComunicUsersHelper.GetSingle(this.props.userID);

		this.setState({ user: user });
	}

	get userProperties(): UserProperty[] {
		return [
			{ name: "Account ID", value: this.user.id },
			{ name: "First name", value: this.user.first_name },
			{ name: "Last name", value: this.user.last_name },
			{ name: "Email address", value: this.user.email },
			{
				name: "Account creation time",
				child: (
					<TimestampWidget time={this.user.account_creation_time} />
				),
			},
			{ name: "Page visibility", value: this.user.page_visibility },
			{
				name: "Virtual directory",
				value: this.user.directory ? "@" + this.user.directory : "None",
			},
			{
				name: "Account image",
				child: <Avatar src={this.user.account_image} />,
			},
			{
				name: "Account image visibility",
				value: this.user.account_image_visibility,
			},
			{
				name: "Is friend list public",
				bool: this.user.friend_list_public,
			},
			{
				name: "Is email address public",
				bool: this.user.is_email_public,
			},
			{
				name: "Personal website",
				value: this.user.personal_website,
			},
			{
				name: "Public note",
				value: this.user.public_note,
			},
			{ name: "Location", value: this.user.location },
			{ name: "Block comments", bool: this.user.block_comments },
			{
				name: "Allow posts from friends",
				bool: this.user.allow_posts_from_friends,
			},
			{ name: "Allow emails", bool: this.user.allow_mails },
			{ name: "Language", value: this.user.lang },
		];
	}

	get userID(): number {
		return this.props.userID;
	}

	get fullName(): string {
		return this.user.first_name + " " + this.user.last_name;
	}

	async changeEmailAddress() {
		try {
			const newEmail = await input({
				title: "Change user email address",
				label: "New email address",
				message:
					"Please input the new email address for " +
					this.fullName +
					":",
				type: "email",
				minLength: 5,
				validateInput: validateEmail,
			});

			if (!newEmail || newEmail.length === 0) return;

			if (
				!(await matConfirm(
					"Do you really want to change email to " +
						newEmail +
						" for " +
						this.fullName
				))
			)
				return;

			await ComunicUsersHelper.ChangeEmail(this.userID, newEmail);

			this.setState((s) => {
				s.user.email = newEmail;
				return s;
			});
		} catch (e) {
			console.error(e);
			matAlert("Failed to update user email address!");
		}
	}

	async createPasswordResetLink() {
		try {
			if (
				!(await matConfirm(
					"Do you really want to create a password recovery link for " +
						this.fullName +
						"?"
				))
			)
				return;

			const link = await ComunicUsersHelper.CreatePasswordRecoveryLink(
				this.userID
			);

			CopyToClipboard(link);

			snackbar(
				"A password reset link has been successfully copied to the clipboard!"
			);
		} catch (e) {
			console.error(e);
			matAlert("Failed to update user email address!");
		}
	}

	async deleteAccount() {
		try {
			if (
				!(await matConfirm(
					"Do you really want to delete the account of " +
						this.fullName +
						"?"
				)) ||
				!(await matConfirm(
					"LAST CHANCE TO COME BACK!!!\nDo you really want to delete the account of " +
						this.fullName +
						"?"
				))
			)
				return;

			await ComunicUsersHelper.DeleteUserAccount(this.userID);

			snackbar("The account has been successfully deleted!");
		} catch (e) {
			console.error(e);
			matAlert("Failed to delete user account!");
		}
	}

	build() {
		const properties = this.userProperties.map((p) => {
			return (
				<TableRow key={p.name} hover>
					<TableCell
						style={{
							fontWeight: "bold",
						}}
					>
						{p.name}
					</TableCell>
					<TableCell>
						{p.child != null
							? p.child
							: p.bool != null
							? p.bool
								? "Yes"
								: "No"
							: p.value == null
							? "Undefined"
							: p.value}
					</TableCell>
				</TableRow>
			);
		});
		return (
			<>
				<PageTitle
					name={this.user.first_name + " " + this.user.last_name}
				/>

				<Grid container spacing={3}>
					<Grid item xs={12}>
						<CustomCard title="General information">
							<Grid container>
								<Grid item xs={6}>
									<Table>
										<TableBody>
											{properties.slice(
												0,
												properties.length / 2
											)}
										</TableBody>
									</Table>
								</Grid>
								<Grid item xs={6}>
									<Table>
										<TableBody>
											{properties.slice(
												properties.length / 2
											)}
										</TableBody>
									</Table>
								</Grid>
							</Grid>
						</CustomCard>
					</Grid>
					<Grid item xs={6}>
						<CustomCard title="Actions">
							<div
								style={{
									padding: "10px",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Button
									variant="outlined"
									color="primary"
									startIcon={<EmailIcon />}
									style={{
										width: "100%",
									}}
									onClick={this.changeEmailAddress}
								>
									Change email address
								</Button>

								<div>&nbsp;</div>

								<Button
									variant="outlined"
									color="primary"
									startIcon={<LinkIcon />}
									style={{ width: "100%" }}
									onClick={this.createPasswordResetLink}
								>
									Create password reset link
								</Button>

								<div>&nbsp;</div>

								<Button
									variant="outlined"
									color="secondary"
									startIcon={<DeleteIcon />}
									style={{ width: "100%" }}
									onClick={this.deleteAccount}
								>
									Delete user account
								</Button>
							</div>
						</CustomCard>
					</Grid>
				</Grid>
			</>
		);
	}

	render() {
		return (
			<AsyncWidget
				key={this.props.userID}
				load={this.load}
				errorMessage="Failed to load user information!"
				onBuild={this.build}
			/>
		);
	}
}
