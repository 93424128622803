/**
 * Accounts list route
 *
 * @author Pierre Hubert
 */

import {
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import { Link } from "react-router-dom";
import { AccountHelper, AdminAccount } from "../../helpers/AccountHelper";
import { canManageAdmins } from "../../utils/AccountUtils";
import { AsyncWidget } from "../widgets/AsyncWidget";
import { input, matAlert, snackbar } from "../widgets/DialogsProvider";
import { PageTitle } from "../widgets/PageTitle";
import { TimestampWidget } from "../widgets/TimestampWidget";

export class AccountsListRoute extends React.Component<
	{},
	{ version: number; list: AdminAccount[] }
> {
	constructor(p: any) {
		super(p);

		this.state = {
			version: 1,
			list: [],
		};

		this.load = this.load.bind(this);
		this.createAccount = this.createAccount.bind(this);
		this.build = this.build.bind(this);
	}

	async load() {
		const list = await AccountHelper.GetAdminsList();
		this.setState({ list: list });
	}

	render() {
		return (
			<AsyncWidget
				errorMessage="Failed to load the list of adminstrators!"
				onBuild={this.build}
				key={this.state.version}
				load={this.load}
			/>
		);
	}

	async createAccount() {
		try {
			const name = await input({
				label: "Administrator name",
				minLength: 5,
				title: "New administrator",
			});

			const email = await input({
				label: "Administrator email",
				minLength: 5,
				title: "New administrator",
			});

			await AccountHelper.CreateAdmin(name, email);

			snackbar("Account successfully created!");
			this.setState({ version: this.state.version + 1 });
		} catch (e) {
			console.error(e);
			matAlert("Failed to create admin account!");
		}
	}

	build() {
		return (
			<div>
				<PageTitle
					name="Administrators list"
					actions={
						canManageAdmins() ? (
							<IconButton onClick={this.createAccount}>
								<AddIcon />
							</IconButton>
						) : (
							<div></div>
						)
					}
				/>

				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align="right">ID</TableCell>
								<TableCell align="right">Name</TableCell>
								<TableCell align="right">
									Email address
								</TableCell>
								<TableCell align="right">
									Account creation
								</TableCell>
								<TableCell align="right">Roles</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.list.map((row) => (
								<TableRow key={row.id}>
									<TableCell align="right">
										{row.id}
									</TableCell>
									<TableCell align="right">
										{row.name}
									</TableCell>
									<TableCell align="right">
										{row.email}
									</TableCell>
									<TableCell align="right">
										<TimestampWidget
											time={row.time_create}
										/>
									</TableCell>
									<TableCell align="right">
										{row.roles.length}
									</TableCell>
									<TableCell>
										<Link to={"/accounts/" + row.id}>
											<IconButton>
												<SettingsIcon />
											</IconButton>
										</Link>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}
}
