/**
 * Base 64 utilities
 *
 * @author Pierre Hubert
 */

import { bytesToBase64 } from "./Base64Lib";

/**
 * Add padding to base64 string
 *
 * Based on : https://gist.github.com/catwell/3046205
 *
 * @param input Input base64, without padding
 */
export function base64AddPadding(input: string): string {
	const remainder = input.length % 4;

	if (remainder === 2) input += "==";
	else if (remainder === 3) input += "=";

	return input.replaceAll("-", "+").replaceAll("_", "/");
}

/**
 * Turn a base64 string without padding into Uint8Array
 *
 * @param input Input base64 (without padding) string
 */
export function base64NoPaddingToUint8Array(input: string): Uint8Array {
	return Uint8Array.from(atob(base64AddPadding(input)), (c) =>
		c.charCodeAt(0)
	);
}

/**
 * Convert a buffer to a base64-encoded string
 *
 * @param buff Buffer to convert
 */
export function ArrayBufferToBase64(buff: ArrayBuffer): string {
	const arr = new Uint8Array(buff);
	return bytesToBase64(arr);
}
