/**
 * Account helper
 *
 * @author Pierre Hubert
 */

import { AuthKey } from "./AdminKeyHelper";
import { serverRequest } from "./APIHelper";

export interface AuthOptions {
	reset_token: string;
	keys: AuthKey[];
}

export type AdminAccountRole =
	| "manage_admins"
	| "manage_users"
	| "access_all_admin_logs";

export interface AdminAccount {
	id: number;
	name: string;
	email: string;
	time_create: number;
	roles: Array<AdminAccountRole>;
}

export interface NewAdminGeneralSettings {
	id: number;
	name: string;
	email: string;
}

export interface AdminResetToken {
	token: string;
	expire: number;
}

export const SESSION_STORAGE_TOKEN = "auth_token";

let currentAccount: AdminAccount;

export class AccountHelper {
	/**
	 * Check whether access token are available
	 * or not
	 */
	static get hasAccessToken(): boolean {
		return sessionStorage.getItem(SESSION_STORAGE_TOKEN) != null;
	}

	/**
	 * Get current user access token
	 */
	static get accessToken(): string {
		const token = sessionStorage.getItem(SESSION_STORAGE_TOKEN);
		if (!token) throw new Error("No access token for now!");
		return token;
	}

	/**
	 * Get authentication options for a given email address
	 *
	 * @param mail Requested email
	 */
	static async getAuthOptions(mail: string): Promise<AuthOptions> {
		return await serverRequest("accounts/auth_options", {
			mail: mail,
		});
	}

	/**
	 * Attempt to authenticate user using a reset token
	 *
	 * @param mail Email address of the admin
	 * @param token The reset token
	 */
	static async authWithResetToken(mail: string, token: string) {
		const res = await serverRequest("accounts/auth_with_reset_token", {
			mail: mail,
			token: token,
		});

		sessionStorage.setItem(SESSION_STORAGE_TOKEN, res.token);
	}

	/**
	 * Get information about an administrator
	 *
	 * @param id The ID of the target administrator
	 */
	static async getAdminInfo(id: number): Promise<AdminAccount> {
		return await serverRequest("accounts/info", {
			id: id,
		});
	}

	/**
	 * Get the entire list of administrators
	 */
	static async GetAdminsList(): Promise<AdminAccount[]> {
		return await serverRequest("accounts/list");
	}

	/**
	 * Create a new administrator
	 */
	static async CreateAdmin(name: string, email: string) {
		await serverRequest("accounts/create", {
			name: name,
			mail: email,
		});
	}

	/**
	 * Attempt to refresh current account information
	 */
	static async refreshCurrentAccountInfo() {
		const res = await serverRequest("accounts/info", {}, true);

		if (res.error) {
			if (res.error > 0) {
				sessionStorage.removeItem(SESSION_STORAGE_TOKEN);
				document.location.href = document.location.href + "";
			}
			throw new Error("Request failed!");
		}

		currentAccount = res;
	}

	/**
	 * Get current account information
	 */
	static get currentAccount(): AdminAccount {
		if (currentAccount == null) throw new Error("Current account is null!");
		return currentAccount;
	}

	/**
	 * Sign out current user
	 */
	static async signOut() {
		try {
			await serverRequest("accounts/sign_out");
		} catch (e) {
			console.error(e);
		}

		sessionStorage.removeItem(SESSION_STORAGE_TOKEN);
		document.location.href = document.location.href + "";
	}

	/**
	 * Update an admin's settings
	 *
	 * @param a New settings
	 */
	static async UpdateGeneralSettings(s: NewAdminGeneralSettings) {
		await serverRequest("accounts/update_general_settings", {
			id: s.id,
			name: s.name,
			email: s.email,
		});
	}

	/**
	 * Generate a access reset token for an admin
	 *
	 * @param adminID The id of the target administrator
	 */
	static async GenerateResetToken(adminID: number): Promise<AdminResetToken> {
		return await serverRequest("accounts/generate_reset_token", {
			id: adminID,
		});
	}
}
