/**
 * 404 Error page
 *
 * @author Pierre Hubert
 */

import { Typography } from "@mui/material";

export function NotFoundRoute() {
	return (
		<div
			style={{
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Typography variant="h3" color="primary">
				404 Not found
			</Typography>
		</div>
	);
}
