/**
 * Clipboard utilities
 *
 * @author Pierre Hubert
 */

/**
 * Copy some piece of text to the clipboard
 *
 * @param str The content to copy
 */
export function CopyToClipboard(str: string) {
	const input = document.createElement("input");
	input.value = str;

	document.body.appendChild(input);

	input.select();
	input.setSelectionRange(0, str.length);
	document.execCommand("copy");

	input.remove();
}
