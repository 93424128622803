/**
 * Account general settings section
 *
 * @author Pierre Hubert
 */

import { TextField, Button } from "@mui/material";
import React from "react";
import { AdminAccount, AccountHelper } from "../../helpers/AccountHelper";
import { snackbar, matAlert } from "../widgets/DialogsProvider";
import { SettingsSection } from "./SettingsSection";

export class GeneralSettings extends React.Component<
	{ admin: AdminAccount; editable: boolean },
	{ newName: string; newEmail: string }
> {
	constructor(p: any) {
		super(p);

		this.state = {
			newName: this.props.admin.name,
			newEmail: this.props.admin.email,
		};

		this.changedName = this.changedName.bind(this);
		this.changedEmail = this.changedEmail.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	changedName(e: React.ChangeEvent<HTMLInputElement>) {
		this.setState({ newName: e.target.value });
	}

	changedEmail(e: React.ChangeEvent<HTMLInputElement>) {
		this.setState({ newEmail: e.target.value });
	}

	get isValid(): boolean {
		return this.state.newEmail.length > 2 && this.state.newName.length > 2;
	}

	async handleSubmit() {
		try {
			if (!this.isValid) return;

			await AccountHelper.UpdateGeneralSettings({
				id: this.props.admin.id,
				name: this.state.newName,
				email: this.state.newEmail,
			});

			snackbar("Successfully updated admin settings!");
		} catch (e) {
			console.error(e);
			matAlert("Failed to update admin settings!");
		}
	}

	render() {
		return (
			<SettingsSection title="General settings">
				<div style={{ margin: "10px" }}>
					<TextField
						required
						label="Name"
						value={this.state.newName}
						onChange={this.changedName}
						style={{ width: "100%", paddingBottom: "20px" }}
						disabled={!this.props.editable}
					/>

					<TextField
						required
						label="Email"
						value={this.state.newEmail}
						onChange={this.changedEmail}
						type="mail"
						style={{ width: "100%", paddingBottom: "20px" }}
						disabled={!this.props.editable}
					/>

					<div style={{ textAlign: "right" }}>
						<Button
							style={{ alignSelf: "end", marginRight: "10px" }}
							disabled={!this.isValid || !this.props.editable}
							onClick={this.handleSubmit}
						>
							Update
						</Button>
					</div>
				</div>
			</SettingsSection>
		);
	}
}
