/**
 * Admin security keys management helper
 *
 * @author Pierre Hubert
 */

import {
	ArrayBufferToBase64,
	base64NoPaddingToUint8Array,
} from "../utils/Base64Utils";
import { SESSION_STORAGE_TOKEN } from "./AccountHelper";
import { serverRequest } from "./APIHelper";

export interface AdminAccountKey {
	id: number;
	name: string;
	time_add: number;
	has_password: boolean;
}
export interface AuthKey {
	name: string;
	id: number;
	password: boolean;
}

export class AdminKeyHelper {
	/**
	 * First step of access key enrollment
	 */
	static async GetKeyRegistrationChallenge(): Promise<any> {
		const res = await serverRequest("keys/challenge_register_key");
		res.publicKey.challenge = base64NoPaddingToUint8Array(
			res.publicKey.challenge
		);
		res.publicKey.user.id = base64NoPaddingToUint8Array(
			res.publicKey.user.id
		);

		return res;
	}

	/**
	 * Register key
	 *
	 * @param name The name of the key to create
	 * @param cred The credentials to register
	 * @param password The password required to use the key
	 */
	static async RegisterKey(
		name: string,
		cred: any,
		password: string
	): Promise<void> {
		const res = {
			id: cred.id,
			rawId: ArrayBufferToBase64(cred.rawId),
			type: cred.type,
			response: {
				attestationObject: ArrayBufferToBase64(
					cred.response.attestationObject
				),
				clientDataJSON: ArrayBufferToBase64(
					cred.response.clientDataJSON
				),
			},
		};

		await serverRequest("keys/register_key", {
			name: name,
			key: JSON.stringify(res),
			password: password,
		});
	}

	/**
	 * First step of security key authentication
	 *
	 * @param mail Target admin account email address
	 * @param key The key to use to authentifcate
	 */
	static async GetAuthenticationChallenge(
		mail: string,
		key: AuthKey
	): Promise<any> {
		const res = await serverRequest("keys/challenge_auth_with_key", {
			mail: mail,
			key_id: key.id,
		});

		res.publicKey.challenge = base64NoPaddingToUint8Array(
			res.publicKey.challenge
		);

		for (let cred of res.publicKey.allowCredentials) {
			cred.id = base64NoPaddingToUint8Array(cred.id);
		}

		return res;
	}

	/**
	 * Attempt to sign in using security key
	 *
	 * @param mail Target admin account email address
	 * @param key Key used to authenticate
	 * @param cred Response to authentication
	 * @param password The password associated with the key (if any)
	 */
	static async AuthenticateWithKey(
		mail: string,
		key: AuthKey,
		cred: any,
		password: string
	): Promise<any> {
		const creds = {
			id: cred.id,
			rawId: ArrayBufferToBase64(cred.rawId),
			type: cred.type,
			response: {
				authenticatorData: ArrayBufferToBase64(
					cred.response.authenticatorData
				),
				clientDataJSON: ArrayBufferToBase64(
					cred.response.clientDataJSON
				),
				signature: ArrayBufferToBase64(cred.response.signature),
				userHandle: cred.response.userHandle,
			},
		};

		const res = await serverRequest("keys/auth_with_key", {
			mail: mail,
			key_id: key.id,
			credential: JSON.stringify(creds),
			password: password,
		});

		sessionStorage.setItem(SESSION_STORAGE_TOKEN, res.token);
	}

	/**
	 * Get the list of keys of an admin
	 *
	 * @param adminID The id of the target administrator
	 */
	static async GetAdminKeys(adminID: number): Promise<AdminAccountKey[]> {
		return await serverRequest("keys/list", {
			id: adminID,
		});
	}

	/**
	 * Delete an admin auth key
	 *
	 * @param adminID The id of the target admin
	 * @param keyID The id of the key to delete
	 */
	static async DeleteAuthKey(adminID: number, keyID: number) {
		return await serverRequest("keys/delete_auth_key", {
			adminID: adminID,
			keyID: keyID,
		});
	}
}
