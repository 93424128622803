/**
 * Role settings section
 *
 * @author Pierre Hubert
 */

import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import React from "react";
import { AdminAccount } from "../../helpers/AccountHelper";
import {
	AdminRole,
	AdminRolesHelper,
	RolesList,
} from "../../helpers/AdminRolesHelper";
import { matAlert, snackbar } from "../widgets/DialogsProvider";
import { SettingsSection } from "./SettingsSection";

export class RoleSettingsSection extends React.Component<{
	admin: AdminAccount;
	editable: boolean;
	onChanged: () => void;
}> {
	constructor(p: any) {
		super(p);

		this.toggleRole = this.toggleRole.bind(this);
	}

	async toggleRole(r: AdminRole, enable: boolean) {
		try {
			await AdminRolesHelper.ToggleAdminRole(
				this.props.admin.id,
				r,
				enable
			);
			this.props.onChanged();
			snackbar("Administrator roles were successfully updated!");
		} catch (e) {
			console.error(e);
			matAlert("Failed to update admin roles!");
		}
	}

	render() {
		return (
			<SettingsSection title="Roles">
				<div style={{ padding: "10px" }}>
					{RolesList.map((r) => {
						const hasRole = this.props.admin.roles.includes(
							r.id as any
						);
						return (
							<Tooltip
								disableFocusListener
								title={r.description}
								placement="top"
								key={r.id}
								arrow
							>
								<FormControlLabel
									disabled={!this.props.editable}
									control={<Checkbox color="primary" />}
									label={r.name}
									checked={hasRole}
									onChange={() =>
										this.toggleRole(r, !hasRole)
									}
									style={{ display: "block" }}
								></FormControlLabel>
							</Tooltip>
						);
					})}
				</div>
			</SettingsSection>
		);
	}
}
