/**
 * Search Coumnic user
 *
 * @author Pierre Hubert
 */

import {
	Avatar,
	Grid,
	ListItem,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
	ComunicUsersHelper,
	SearchResult,
	SearchResult as UserSearchResult,
} from "../../helpers/ComunicUsersHelper";
import { AsyncWidget } from "../widgets/AsyncWidget";
import { PageTitle } from "../widgets/PageTitle";

export class SearchComunicUsersRoute extends React.Component<
	{},
	{ name: string; email: string; results: UserSearchResult[] }
> {
	constructor(p: any) {
		super(p);
		this.state = {
			name: "",
			email: "",
			results: [],
		};

		this.updateName = this.updateName.bind(this);
		this.updateEmailAddress = this.updateEmailAddress.bind(this);
		this.performSearch = this.performSearch.bind(this);
		this.buildResults = this.buildResults.bind(this);
	}

	updateName(e: React.ChangeEvent<HTMLInputElement>) {
		this.setState({
			name: e.target.value,
		});
	}

	updateEmailAddress(e: React.ChangeEvent<HTMLInputElement>) {
		this.setState({
			email: e.target.value,
		});
	}

	get canPerformSearch(): boolean {
		return this.state.name.length > 2 || this.state.email.length > 4;
	}

	get searchKey(): string {
		return this.state.name + "##" + this.state.email;
	}

	async performSearch() {
		const key = this.searchKey;
		const results = await ComunicUsersHelper.SearchUser(
			this.state.name,
			this.state.email
		);

		if (key !== this.searchKey) return;

		this.setState({ results: results });
	}

	render() {
		return (
			<div>
				<PageTitle name="Search Comunic user" />

				{/* Filter users */}
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField
							label="Name"
							style={{ width: "100%" }}
							value={this.state.name}
							onChange={this.updateName}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Email address"
							style={{ width: "100%" }}
							type="email"
							value={this.state.email}
							onChange={this.updateEmailAddress}
						/>
					</Grid>
				</Grid>

				{/* Perform search & display results */}
				{this.canPerformSearch ? (
					<AsyncWidget
						load={this.performSearch}
						onBuild={this.buildResults}
						errorMessage="Failed to search users!"
						key={this.searchKey}
					/>
				) : (
					<div></div>
				)}
			</div>
		);
	}

	buildResults() {
		const paperStyle = { padding: "10px", marginTop: "10px" };

		if (this.state.results.length === 0)
			return (
				<Paper style={paperStyle}>
					There is no result for your search.
				</Paper>
			);

		return (
			<Paper style={paperStyle}>
				{this.state.results.map((result) => {
					return <ResultItem key={result.id} user={result} />;
				})}
			</Paper>
		);
	}
}

function ResultItem(p: { user: SearchResult }) {
	const n = useNavigate();
	const openButton = () => n("/user/" + p.user.id);
	return (
		<ListItem button onClick={openButton}>
			<Grid container wrap="nowrap" spacing={2}>
				<Grid item>
					<Avatar src={p.user.account_image}>
						{p.user.first_name.substring(0, 1)}
					</Avatar>
				</Grid>
				<Grid item xs>
					<Typography>
						{p.user.first_name} {p.user.last_name}
					</Typography>
					<Typography color="textSecondary">
						<i>{p.user.email}</i>
					</Typography>
				</Grid>
			</Grid>
		</ListItem>
	);
}
