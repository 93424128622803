import { Tooltip } from "@mui/material";
import React from "react";

/**
 * Display in human-readable way a timestamp
 */
export function TimestampWidget(props: { time: number }) {
	const date = new Date(props.time * 1000);
	const currDate = new Date();
	const diff = Math.floor(currDate.getTime() / 1000 - props.time);

	let diffStr = diff + " seconds ago";

	if (diff === 0) diffStr = "Just now";
	else if (diff === 1) diffStr = "1 second ago";
	else if (diff === 60) diffStr = "1 minute ago";

	if (diff > 60) diffStr = Math.floor(diff / 60) + " minutes ago";

	if (diff === 3600) diffStr = "1 hour ago";

	if (diff > 3600) diffStr = Math.floor(diff / 3600) + " hours ago";

	const daysAgo = Math.floor(diff / (3600 * 24));
	if (daysAgo === 1) diffStr = "1 day ago";
	else if (daysAgo > 1) diffStr = daysAgo + " days ago";

	const monthsAgo = Math.floor(daysAgo / 30);
	if (monthsAgo === 1) diffStr = "1 month ago";
	else if (monthsAgo > 1) diffStr = monthsAgo + " months ago";

	const yearsAgo = Math.floor(monthsAgo / 12);
	if (yearsAgo === 1) diffStr = "1 year ago";
	else if (yearsAgo > 1) diffStr = yearsAgo + " years ago";

	return (
		<Tooltip
			disableFocusListener
			title={date.toString()}
			placement="top"
			arrow
		>
			<span>{diffStr}</span>
		</Tooltip>
	);
}
